define('tm-common/models/user', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        firstName: attr('string'),
        lastName: attr('string'),
        email: attr('string'),
        userName: attr('string'),
        password: attr('string'),
        salt: attr('string'),
        passwordExpired: attr('string'),
        status: attr('string'),
        lastLogin: attr('estdate'),
        lastLogout: attr('estdate'),
        tmNum: attr('number'),
        tmType: attr('string'),
        salesforceId: attr('string'),
        token: attr('string'),
        tokenExpires: attr('string'),
        userType: attr('string'),
        avatarId: attr('number'),

        currentEmployerName: attr('string'),
        currentEmployerCity: attr('string'),
        currentEmployerId: attr('string'),

        lastWelcomeEmailSentAt: attr('string'),
        lastWelcomeEmailSubject: attr('string'),

        phoneNumber: attr('string'),

        // relationship
        employee: belongsTo('employee', { async: true, inverse: 'user' }),

        createdBy: belongsTo('employee', {
            async: false
        }), // because the FK is user_id
        updatedBy: belongsTo('employee', {
            async: false
        }),
        userAddrs: hasMany('user-addr', {
            async: false
        }),
        userUrls: hasMany('user-url', {
            async: false
        }),
        userNumbers: hasMany('user-number', {
            async: false
        }),
        matterHasUser: hasMany('matter-has-user', {
            async: false
        }),
        firmHasClients: hasMany('firm-has-client', {
            async: false
        }),
        userEventLogs: hasMany('user-event-log', {
            async: false,
            inverse: 'createdBy'
        }),
        userHasGroups: hasMany('user-has-groups', {
            async: false
        }),
        notes: hasMany('note', {
            async: true,
            inverse: 'createdBy'
        }),

        documentTemplates: hasMany('document-template', { async: true }),

        // calculated
        fullName: Ember.computed('firstName', 'lastName', function () {
            if (!this.get('lastName') && !this.get('firstName')) {
                return null;
            }

            var fullName = this.get('lastName') + ', ' + this.get('firstName');

            if (this.get('userType') === 'employee') fullName = this.get('realFullName');

            return Ember.$('<div/>').html(fullName).text();
        }),

        realFullName: Ember.computed('firstName', 'lastName', function () {
            var _getProperties = this.getProperties('firstName', 'lastName'),
                firstName = _getProperties.firstName,
                lastName = _getProperties.lastName;

            return firstName + ' ' + lastName;
        }),

        welcomeFrom: Ember.computed('lastWelcomeEmailSubject', function () {
            switch (this.get('lastWelcomeEmailSubject')) {
                case 'Welcome to the Smith & Carson Client Portal':
                    return 'Welcome Sent - TM';
                case 'Welcome to JurorSMARTS':
                    return 'Welcome Sent - JS';
                case 'Welcome to Smith & Carson Portal':
                    return 'Welcome Sent - SC';
                default:
                    return 'Welcome Sent';
            }
        }),

        isActive: Ember.computed.equal('status', 'Active'),

        primaryAddress: Ember.computed('userAddrs.[]', function () {
            return this.get('userAddrs').findBy('primary', 1);
        }),

        workAddress: Ember.computed('userAddrs.[]', function () {
            return this.get('userAddrs').findBy('type', 'Work');
        }),

        mainFirm: Ember.computed('firmHasClients.[]', function () {
            var firmHasClients = this.get('firmHasClients');
            var mainFirm = void 0;
            var currentEmployer = firmHasClients.findBy('relationship', 'Current Employer');
            if (Ember.isPresent(currentEmployer)) {
                mainFirm = currentEmployer;
            } else {
                mainFirm = firmHasClients.get('firstObject');
            }
            if (Ember.isPresent(mainFirm)) {
                var rel = mainFirm.get('relationship');
                return _emberData.default.PromiseObject.create({
                    promise: mainFirm.get('firm').then(function (firm) {
                        var firmName = firm.get('name');
                        return rel + ': ' + firmName;
                    })
                });
            }

            return _emberData.default.PromiseObject.create({
                promise: new Ember.RSVP.Promise(function (resolve, reject) {
                    resolve('');
                })
            });
        }),

        shortName: Ember.computed('firstName', 'lastName', function () {
            var first = this.get('firstName') || '';
            var last = this.get('lastName') || '';

            if (this.get('userType') === 'employee') {
                return first + ' ' + last.substring(0, 1);
            }

            return last + ', ' + first.substring(0, 1);
        }),

        // only for UI
        groupName: attr(),

        // method use to impersonate another user
        impersonateUser: (0, _emberApiActions.collectionAction)({ path: 'impersonate-user', type: 'post' }),

        switchApp: (0, _emberApiActions.collectionAction)({ path: 'switch-app', type: 'post' }),

        owners: (0, _emberApiActions.collectionAction)({
            path: 'matter_owners',
            type: 'get'
        })
    });
});