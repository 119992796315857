define('tm-common/models/budget', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr('string'),
        amount: attr('number', { defaultValue: 0 }),
        estimatedHours: attr('number', { defaultValue: 0 }),
        trackedType: attr('string'),
        prevAmount: attr('number', { defaultValue: 0 }),
        notes: attr('string'),
        enabled: attr('number', { defaultValue: 1 }),
        includeExpenses: attr('number', { defaultValue: 0 }),
        noBudget: attr('number', { defaultValue: 0 }),
        isBlended: attr('number', { defaultValue: 0 }),
        flatRate: attr('number', { defaultValue: 0 }),
        flatItems: attr('string', { defaultValue: '[]' }),
        startOn: attr('string'),
        dueOn: attr('string'),
        deadlineMet: attr('number', { defaultValue: 0 }),
        hardDeadline: attr('number', { defaultValue: 0 }),
        initialDueOn: attr('string'),
        pushedFlag: attr('string'),
        weight: attr('number', { defaultValue: 0 }),
        createdBy: belongsTo('user', { async: true }),
        updatedBy: belongsTo('user', { async: true }),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),

        // relationships
        matter: belongsTo('matter', {
            async: false,
            inverse: 'budgets'
        }),
        billables: hasMany('billable', {
            async: false
        }),
        billableGroups: hasMany('billable-group', { async: true }),

        isLinkedToBillable: Ember.computed('billables.length', function () {
            return this.get('billables.length') > 0;
        }),
        // get progress of how many hours/dollars used of budget
        getProgress: (0, _emberApiActions.memberAction)({
            path: 'progress',
            type: 'get',
            urlType: 'findRecord'
        }),

        addFlatService: function addFlatService() {
            var items = this.get('flatServiceItems');
            items.pushObject(Ember.Object.create({
                qty: 1,
                description: '',
                amount: 0
            }));
            this.set('flatServiceItems', items);
        },

        removeFlatService: function removeFlatService(service) {
            var items = this.get('flatServiceItems');
            items.removeObject(service);
            this.set('flatServiceItems', items);
        },

        amountFromFlatService: Ember.computed('flatServiceItems.@each.{qty,amount}', function () {
            return this.get('flatServiceItems').reduce(function (acc, item) {
                return acc + item.get('qty') * item.get('amount');
            }, 0);
        }),

        flatServiceItems: Ember.A(),

        parseItems: function parseItems() {
            this.set('flatServiceItems', Ember.A());
            this.get('flatServiceItems').pushObjects(JSON.parse(this.get('flatItems')).map(function (x) {
                return Ember.Object.create(x);
            }));
        },
        updateEstimatedHours: function updateEstimatedHours(budgetChangeset, avgRate, matterHourlyRate, budgetAmountFlat) {
            var amountFromFlatService = budgetAmountFlat || this.get('amountFromFlatService');
            var rate = matterHourlyRate || avgRate;

            if (budgetChangeset.get('amount') !== 0 && !budgetChangeset.get('flatRate')) {
                budgetChangeset.set('estimatedHours', Number(budgetChangeset.get('amount') / rate).toFixed(2));
            }

            if (amountFromFlatService !== 0 && budgetChangeset.get('flatRate')) {
                budgetChangeset.set('estimatedHours', Number(amountFromFlatService / rate).toFixed(2));
            }
        },


        selected: attr('boolean', { defaultValue: true }), // this is used by the UI to determine whether the billable has been selected or not.  it doesn't have a counterpart value on the API,

        updateWeights: (0, _emberApiActions.collectionAction)({
            path: 'update-weights',
            type: 'put'
        })
    });
});